import request from "@/utils/request";

export function queryPageButton(data) {
  return request({
    url: "/sys/sysPermission/queryPageButton/" + data,
    method: "get",
  });
}

export function queryPermissionDict() {
  return request({
    url: "/sys/sysPermission/queryPermissionDict",
    method: "get",
  });
}

export function queryPermission() {
  return request({
    url: "/sys/sysPermission/queryPermission",
    method: "get",
  });
}

export function addPermission(data) {
  return request({
    url: "/sys/sysPermission/addPermission",
    method: "post",
    data,
  });
}

export function getPermission(data) {
  return request({
    url: "/sys/sysPermission/getPermission/" + data,
    method: "get",
  });
}

export function updatePermission(data) {
  return request({
    url: "/sys/sysPermission/updatePermission",
    method: "post",
    data,
  });
}

export function disablePermission(data) {
  return request({
    url: "/sys/sysPermission/disablePermission",
    method: "post",
    data,
  });
}

export function enablePermission(data) {
  return request({
    url: "/sys/sysPermission/enablePermission",
    method: "post",
    data,
  });
}

export function deletePermission(data) {
  return request({
    url: "/sys/sysPermission/deletePermission",
    method: "post",
    data,
  });
}
