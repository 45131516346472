<template>
  <div class="cardBody">
    <div
      style="
        width: 90%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      "
    >
      <el-form
        :model="configureForm"
        status-icon
        :rules="rulesConfigure"
        ref="configureForm"
        label-width="200px"
      >
        <el-form-item label="人像比对同一人:" prop="comparisonSame">
          <el-input
            v-model="configureForm.comparisonSame"
            autocomplete="off"
            placeholder="人像比对同一人"
            :rows="4"
            size="small"
          ></el-input>
          %
        </el-form-item>
        <el-form-item label="人像比对可能是同一人:" prop="comparisonLike">
          <el-input
            v-model="configureForm.comparisonLike"
            placeholder="人像比对可能是同一人"
            autocomplete="off"
            size="small"
          ></el-input>
          %
        </el-form-item>
        <el-form-item label="人像比对非同一人:" prop="comparisonNo">
          <el-input
            v-model="configureForm.comparisonNo"
            placeholder="人像比对非同一人"
            autocomplete="off"
            size="small"
          ></el-input
          >%
        </el-form-item>
        <el-form-item label="人证比对率:" prop="baiduFace ">
          <el-input
            v-model="configureForm.baiduFace"
            placeholder="人证比对率"
            autocomplete="off"
            size="small"
          ></el-input
          >%
        </el-form-item>
        <el-form-item label="百度appid:" prop="appid">
          <el-input
            v-model="configureForm.appid"
            placeholder="百度appid"
            autocomplete="off"
            size="small"
          ></el-input
          >%
        </el-form-item>
        <el-form-item label="百度apikey:" prop="apikey">
          <el-input
            v-model="configureForm.apikey"
            placeholder="百度apikey"
            autocomplete="off"
            size="small"
          ></el-input
          >%
        </el-form-item>
        <el-form-item label="百度apisecret:" prop="secret">
          <el-input
            v-model="configureForm.secret"
            placeholder="百度apisecret"
            autocomplete="off"
            size="small"
          ></el-input
          >%
        </el-form-item>
      </el-form>
    </div>
    <div style="display: flex; justify-content: flex-end">
      <el-button
        style="width: 120px"
        type="primary"
        @click="submitForm()"
        v-if="updataButton"
        size="small"
        >更新
      </el-button>
    </div>
  </div>
</template>

<script>
// import { dateFormat } from "@/utils/date";
import { getComparisonParam, updateComparisonParam } from "@/api/contrast";
import { queryPageButton } from "@/api/permission";

export default {
  name: "contrastConfigure",
  data() {
    var validateComparisonSame = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入人像比对同一人率"));
      } else {
        callback();
      }
    };
    var validateComparisonLike = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入人像比对可能是同一人率"));
      } else {
        callback();
      }
    };
    var validateComarisonNo = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入人像比对非同一人率"));
      } else {
        callback();
      }
    };
    var validateBaiduFace = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入人证比对率"));
      } else {
        callback();
      }
    };
    var validateApikey = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入百度appid"));
      } else {
        callback();
      }
    };
    var validateSecret = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入百度apikey"));
      } else {
        callback();
      }
    };
    var validateAppid = (rule, value, callback) => {
      if (!value) {
        callback(new Error("百度apisecret"));
      } else {
        callback();
      }
    };
    return {
      rulesConfigure: {
        comparisonSame: [
          {
            validator: validateComparisonSame,
            trigger: "blur",
          },
        ],
        comparisonLike: [
          {
            validator: validateComparisonLike,
            trigger: "blur",
          },
        ],
        comparisonNo: [
          {
            validator: validateComarisonNo,
            trigger: "blur",
          },
        ],
        baiduFace: [
          {
            validator: validateBaiduFace,
            trigger: "blur",
          },
        ],
        appid: [
          {
            validator: validateAppid,
            trigger: "blur",
          },
        ],
        apikey: [
          {
            validator: validateApikey,
            trigger: "blur",
          },
        ],
        secret: [
          {
            validator: validateSecret,
            trigger: "blur",
          },
        ],
      },
      configureForm: {},
      //权限按钮
      updataButton:false
    };
  },
  created() {
    this.query();
    this.queryButton();

  },
  methods: {
    queryButton() {
      const data = 161;
      queryPageButton(data).then((resp) => {
        // console.log(resp);
        for (const button of resp.data) {
          if (button.code === "updataButton") {
            this.updataButton = true;
          }
      
        }
      });
    },
    query() {
      this.tableLoading = true;
      getComparisonParam().then((resp) => {
        if (resp.code == 0 || resp.data) {
          this.configureForm = resp.data;
        } else {
          this.$notify.error({
            title: "错误",
            message: resp.message,
          });
        }
      });
    },
    submitForm() {
      this.$refs.configureForm.validate((valid) => {
        if (valid) {
          updateComparisonParam(this.configureForm)
            .then((resp) => {
              this.$notify({
                title: "修改成功",
                message: resp.message,
                type: "success",
              });
            })
            .catch((e) => {
              console.log(e.responseText);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style>
.card {
  height: calc(100% - 2px);
}
.cardBody {
  height: calc(100% - 40px);
}

.appMain-leave-active,
.appMain-enter-active {
  transition: all 0.5s;
}

.appMain-enter {
  opacity: 0;
  transform: translateX(-30px);
}

.appMain-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
.el-form-item__content {
  display: flex;
}
</style>
