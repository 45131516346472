import request from "@/utils/request";

// 比对配置
export function getComparisonParam() {
  return request({
    url: "/sys/sysParam/getComparisonParam",
    method: "get",
  });
}

export function updateComparisonParam(data) {
  return request({
    url: "/sys/sysParam/updateComparisonParam",
    headers: {
      "Content-Type": "application/json",
    },
    method: "post",
    data,
  });
}

// 选择校拍任务

export function taskComparisonPhoto(data = {}, currentPage, pageSize) {
  return request({
    url:
      "/sys/taskInfo/taskComparisonPhoto?currentPage=" +
      currentPage +
      "&pageSize=" +
      pageSize,
    headers: {
      "Content-Type": "application/json",
    },
    method: "post",
    data,
  });
}

// 校拍任务保存

export function comparisonTaskAdd(data = {}) {
  return request({
    url: "/sys/comparisonTask/add",
    headers: {
      "Content-Type": "application/json",
    },
    method: "post",
    data,
  });
}

// 对比列表

export function comparisonTaskList(data = {}, currentPage, pageSize) {
  return request({
    url:
      "/sys/comparisonTask/list?currentPage=" +
      currentPage +
      "&pageSize=" +
      pageSize,
    headers: {
      "Content-Type": "application/json",
    },
    method: "post",
    data,
  });
}

// 学生信息
export function comparisonTaskstudentList(data, currentPage, pageSize) {
  let id = data.id;
  return request({
    url:
      "/sys/comparisonTask/studentList/" +
      id +
      "?currentPage=" +
      currentPage +
      "&pageSize=" +
      pageSize,
    headers: {
      "Content-Type": "application/json",
    },
    method: "post",
    data,
  });
}

// 导出失败学生
export function exportFail(id) {
  return request({
    url: "/sys/comparisonTask/exportFail?id=" + id,
    method: "get",
  });
}

// 查看对比结果

export function resultTotal(id) {
  return request({
    url: "/sys/comparisonTask/resultTota/" + id,
    headers: {
      "Content-Type": "application/json",
    },
    method: "post",
  });
}

// 查看对比结果---人证列表
export function resultList(data, currentPage, pageSize) {
  return request({
    url:
      "/sys/comparisonTask/resultList/" +
      data.id +
      "?currentPage=" +
      currentPage +
      "&pageSize=" +
      pageSize,
    headers: {
      "Content-Type": "application/json",
    },
    method: "post",
    data
  });
}

// 下载对比报告
export function downloadReport(id) {
  return request({
    url: "/sys/comparisonTask/downloadReport?id=" + id,
    method: "get",
  });
}


// 文件上传
export function uploadFile(data) {
  return request({
    url: "/sys/comparisonTask/reUploadPhoto",
    method: "post",
    data,
  });
}